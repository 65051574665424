<template>
  <v-container class="px-10" fluid>
    <v-popup-loading :isLoading="isLoadingBranch" />
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="linkRoute"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">Doctor</h3>
          <v-btn
            id="add-btn"
            v-if="hasPermCreate()"
            large
            color="primary600Color"
            @click="create"
            class="white-text-button button-bold"
            t-data="create-btn"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Create Doctor
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-preview
      v-if="isPreview"
      :show="isPreview"
      :close-preview="closePreview"
      :doctor-object="previewDoctor"
    />
    <v-alert-confirm
      :show="isDelete"
      :title="'Remove Confirmation'"
      :subTitle="subTitle"
      :close="closeDeletePopup"
      :headline-color="'black'"
      :buttonText="'Remove'"
      :confirm="confirmDelete"
      :icon="''"
    ></v-alert-confirm>
    <edit-order-dialog
      :show="isEditOrder"
      :data="editOrderObject"
      :close="closeEditOrder"
      title-key="name"
      @save="saveEditOrder"
    ></edit-order-dialog>

    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>

    <v-card>
      <v-card-title :class="{'mb-0': true, 'pb-0': isSelectedBranch}">
        <v-row>
          <v-col cols="12" sm="4" class="mt-0 pt-0 pb-0">
            <single-selected-branch
              outlined
              required
              dense
              title="Branch"
              :branch="orgList.all"
              :selectedBranch="selectedBranch"
              :syncData="changedSelectedBranch"
              :rule="v => !!v || 'Please select branch'"
            ></single-selected-branch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pt-0 pb-2" v-if="showSearchAndFilter">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12" sm="4" t-data="search-panel">
            <search
              :search="search"
              label="Search by Doctor Name"
              @input="textSearch"
              @clear="clearSearch()"
            ></search>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12" sm="6"></v-col>
          <v-col
            cols="12"
            sm="2"
            class="d-flex justify-end align-center pt-0 pb-0"
            t-data="filter-panel"
          >
            <filter-dropdown :key="componentKey" @apply="handleApplyFilter"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-tabs
        v-if="isSelectedBranch"
        v-model="tab"
        class="status-tab fix-height-tab body-regular"
        color="primary500Color"
        slider-color="primary500Color"
        align-with-title
        @change="fetchByTab"
      >
        <v-tab class="fix-width-tab" v-for="item in tabItems" :key="item">
          {{ item }}
        </v-tab>
        <v-tab-item>
          <v-layout fluid v-resize="onResize" v-if="isSelectedBranch">
            <v-data-table
              :headers="headers"
              :items="doctorList"
              :loading="isLoading"
              :options.sync="options"
              :items-per-page="pageSize"
              :server-items-length="total"
              :footer-props="{'items-per-page-options': itemsPerPage}"
              item-key="id"
              :sort-desc="true"
              class="elevation-1 custom-table"
              fixed-header
              style="width: 100%"
              :height="
                windowSize.y - heightOptions.header - heightOptions.tableFooter
              "
            >

              <template v-slot:item.name="{item}" style="width: 190px">
                <v-list-item-content
                  class="d-inline-block text-truncate"
                  style="width: 190px"
                >
                  <v-list-item-title style="padding: 2px">{{
                    item.contents[0].code === 'th'
                      ? item.contents[0].name
                      : item.contents[1].name
                  }}</v-list-item-title>
                  <v-list-item-subtitle style="padding: 2px">{{
                    item.contents[0].code === 'en'
                      ? item.contents[0].name
                      : item.contents[1].name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <template v-slot:item.org_codename="{item}">{{
                listBranch(item.orgs)
              }}</template>

<template v-slot:item.department="{item}" style="width: 190px">
                <v-tooltip
                  class="px-2"
                  color="rgba(0, 0, 0, 0.85)"
                  top
                  v-if="item.departments.length > 0"
                >
                  <template v-slot:activator="{on, attrs}">
                    <div v-bind="attrs" v-on="on">
                      <slot name="department">{{ item.department }}</slot>
                    </div>
                  </template>
                  <div class="sub-body-bold" t-data="tooltip-title">
                    Department
                  </div>
                  <div
                    class="body-regular"
                    v-for="(value, index) in item.departments"
                    :key="index"
                  >
                    {{ index + 1 }}. {{ value }}
                  </div>
                </v-tooltip>
                <v-list-item-content
                  v-else
                  class="d-inline-block text-truncate"
                  style="width: 190px"
                >
                  <v-list-item-title style="padding: 2px">{{
                    item.department
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-slot:item.status="{item}">
                <v-switch
                  t-data="status-switch"
                  color="primary200Color"
                  v-model="item.status"
                  :false-value="inActiveStatus"
                  :true-value="activeStatus"
                  :disabled="!hasPermEditByOrgs(item.orgs)"
                  @change="event => handleSwitchStatusChange(event, item)"
                  class="mb-0 pb-0 custom-switch"
                ></v-switch>
              </template>
              <template v-slot:item.update_date="{item}">{{
                !!item.update_date ? dateFmt(item.update_date) : ''
              }}</template>
              <template v-slot:item.update_by_user="{item}"
                ><username-status-text :user="item.update_by_user"
              /></template>
              <template v-slot:item.action="{item}">
                <v-icon
                  t-data="preview-action"
                  class="px-2 action-icon"
                  @click="showPreview(item)"
                  >mdi-magnify</v-icon
                >
                <v-icon
                  t-data="edit-action"
                  class="px-2 action-icon"
                  @click="edit(item.id)"
                  >mdi-pencil-outline</v-icon
                >
                <v-icon
                  v-if="hasPermDeleteByOrgs(item.orgs)"
                  t-data="delete-action"
                  class="px-2 action-icon"
                  @click="showDeletePopup(item.id)"
                  >mdi-trash-can-outline</v-icon
                >
                <v-icon
                  v-else
                  class="px-2 action-icon transparent-icon"
                  t-data="empty-icon"
                ></v-icon>
              </template>
            </v-data-table>
          </v-layout>
          <div
            v-if="isSelectedBranch"
            class="table-footer-prepend"
            t-data="total-result-content"
          >
            <div class="vertical-center pl-4">
              <b>{{ total }}</b> Search results
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <sortable-table
            @updateSuccess="showSuccessSnackbar(actionEnumData.STATUS_UPDATED)"
            @updateFail="showFailSnackbar(actionEnumData.STATUS_UPDATED)"
          ></sortable-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import SortableTable from '@/components/doctor/SortableTable'
import EditOrderDialog from '@/components/EditOrderDialog'
import PopupLoading from '@/components/PopupLoading'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import {mapActions, mapState} from 'vuex'
import {
  hasPermision,
  searchHandler,
  snackbarSuccessContent,
  snackbarFailContent,
  hasPermissionsByOrgs,
  convertDate,
  convertSelectedData, swapArrayElements
} from '@/helper/helper'
import Preview from '@/components/doctor/Preview'
import SnackBar from '@/components/SnackBar'
import {actionEnum, tabEnum} from '@/constants/constants'
import Search from '@/components/Search'
import FilterDropdown from '@/views/doctor/FilterDropdown'
import {doctorStatusEnum} from '@/constants/doctor'
import AlertConfirm from '@/components/AlertConfirm'
import UsernameStatusText from '@/components/UsernameStatusText'

export default {
  components: {
    'v-alert-confirm': AlertConfirm,
    'v-popup-loading': PopupLoading,
    'v-preview': Preview,
    EditOrderDialog,
    FilterDropdown,
    Search,
    SingleSelectedBranch,
    SnackBar,
    SortableTable,
    UsernameStatusText,
  },
  data() {
    return {
      isPreview: false,
      previewDoctor: {},
      pageName: 'doctor',
      heightOptions: {
        header: 420,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: 'created',
      subTitle:
        'Are you sure you want to remove this doctor? This action will remove content in both Thai and English.',
      forceUpdateSelect: '',
      deleteID: '',
      action: {
        status: '',
        dropdownActions: [
          {text: 'Active', value: 0},
          {text: 'Inactive', value: 1}
        ]
      },
      selectedBranch: '',
      componentKey: 0,
      tab: 0,
      options: {},
      pageSize: 25,
      search: '',
      linkRoute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'Doctor',
          disabled: true,
          href: '/'
        }
      ],
      status: '',
      itemsPerPage: [25, 50, 100],
      headers: [
        {
          text: 'Doctor Name',
          align: 'left',
          value: 'name',
          sortable: true,
          width: 190
        },
        {
          text: 'Branch',
          align: 'left',
          value: 'org_codename',
          sortable: true,
          width: 113
        },
        {
          text: 'Department',
          align: 'left',
          value: 'department',
          sortable: false,
          width: 190,
        },
        {
          text: 'Updated Date/Time',
          align: 'left',
          value: 'update_date',
          sortable: false,
          width: 196
        },
        {
          text: 'Updated By',
          align: 'left',
          value: 'update_by_user',
          sortable: false,
          width: 162
        },
        {
          text: 'Status',
          align: 'center',
          value: 'status',
          sortable: false,
          width: 70
        },
        {
          text: 'Actions',
          align: 'center',
          value: 'action',
          sortable: false,
          width: 140
        }
      ],
      activeStatus: doctorStatusEnum.ACTIVE,
      inActiveStatus: doctorStatusEnum.INACTIVE,
      actionEnumData: actionEnum,
    }
  },
  computed: {
    isSelectedBranch() {
      return this.orgList.selected.length > 0
    },
    showSearchAndFilter() {
      return this.isSelectedBranch && this.tabItems[this.tab] === tabEnum.LIST
    },
    tabItems() {
      return Object.values(tabEnum)
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('doctor', [
      'doctorList',
      'doctorObject',
      'editOrderObject',
      'isDelete',
      'isEditOrder',
      'isLoading',
      'isLoadingBranch',
      'orgList',
      'sortableList',
      'total',
      'updateIdList'
    ])
  },
  created() {
    this.resetList()
    this.setListOrgInfo()
    this.fetchSpecialty()
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  methods: {
    dateFmt: date => convertDate(date),
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    textSearch(data) {
      this.search = data
      this.timer = searchHandler(this.timer, clearTimeout, () => {
        this.setTextSearch(this.search.trim())
        this.options.page = 1
        this.fetch()
      })
    },
    async saveEditOrder(data) {
      this.setShowEditOrder(false)
      const {oldOrderNo, newOrderNo} = data
      const newOrderList = swapArrayElements(
        this.sortableList,
        oldOrderNo - 1,
        newOrderNo - 1
      )
      await this.reorderList(newOrderList)
      this.showSuccessSnackbar(actionEnum.ORDER_UPDATED)
    },
    async fetchByTab(data) {
      this.resetSearchAndFilterData()
      if (this.tabItems[data] === tabEnum.SORT) {
        await this.fetchSortableList()
      }
      if (this.tabItems[data] === tabEnum.LIST) {
        await this.fetch()
      }
    },
    hasPermCreate() {
      return hasPermision('CanAddDoctor')
    },
    hasPermEdit() {
      return hasPermision('CanEditDoctor')
    },
    hasPermEditByOrgs(orgs) {
      return hasPermissionsByOrgs('CanEditDoctor', orgs)
    },
    hasPermDeleteByOrgs(orgs) {
      return hasPermissionsByOrgs('CanDeleteDoctor', orgs)
    },
    resetSearchAndFilterData() {
      this.options.page = 1
      this.componentKey++
      this.search = ''
      this.resetSearchAndFilter()
    },
    changedSelectedBranch(value) {
      this.resetDepartmentList()
      if (value.length > 0) {
        this.orgList.selected = []
        this.orgList.selected = convertSelectedData(
          value,
          this.orgList.all,
          this.orgList.selected
        )
        this.fetchDepartment(value)
        this.fetchByTab(this.tab)
      }
    },
    clearSearch() {
      this.search = ''
      this.options.page = 1
      this.setTextSearch('')
      this.fetch()
    },
    listBranch(orgs) {
      return orgs.map(org => org.toUpperCase()).join(', ')
    },
    async confirmDelete() {
      const isSuccess = await this.deletePkg()
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.REMOVED)
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    create() {
      this.$router.push({name: 'doctor-create'})
    },
    edit(item) {
      this.$router.push({name: 'doctor-edit', params: {id: item}})
    },
    closeDeletePopup() {
      this.setDeletePopup(false)
    },
    showDeletePopup(id) {
      this.setDeleteId(id)
      this.setDeletePopup(true)
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    async showPreview(item) {
      await this.setDoctorId(item.id)
      this.previewDoctor = this.doctorObject
      this.previewDoctor.department = item.department
      this.isPreview = true
    },
    closePreview() {
      this.isPreview = false
    },
    closeEditOrder() {
      this.setShowEditOrder(false)
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    async handleSwitchStatusChange(event, row) {
      this.setSelectedId([row])
      const isSuccess = await this.update(event)
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.STATUS_UPDATED)
      } else {
        this.showFailSnackbar(actionEnum.STATUS_UPDATED)
      }
    },
    ...mapActions('doctor', [
      'deletePkg',
      'fetch',
      'fetchDepartment',
      'fetchSortableList',
      'fetchSpecialty',
      'reorderList',
      'resetDepartmentList',
      'resetList',
      'resetSearchAndFilter',
      'setDeleteId',
      'setDeletePopup',
      'setDoctorId',
      'setFieldSort',
      'setListOrgInfo',
      'setPage',
      'setPageSize',
      'setSelectedId',
      'setShowEditOrder',
      'setSort',
      'setTextSearch',
      'update'
    ])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.seachBox {
  padding: 10px;
  margin-top: 22px;
}
.lineFont {
  font-size: 13px;
}

.v-tooltip__content {
  padding: 8px 16px !important;
}

</style>
