<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="mt-2 ml-5 mr-5 fixWidth d-block">
        <p
          class="filterContentTitle sub-body-bold mb-0"
          t-data="department-title"
        >
          Department
        </p>
        <v-select
          class="can-disable-selector selector-filter"
          t-data="department-selector"
          placeholder="Select Department"
          :items="departmentList"
          outlined
          required
          color="primary500Color"
          dense
          v-model="departmentSelected"
          item-text="name"
          item-value="id"
          :disabled="departmentList.length <= 0"
        ></v-select>
      </v-list-item>

      <v-list-item class="ml-2 mr-2 mt-2">
        <div class="d-flex flex-column justify-start align-start">
          <p class="mb-0 filterContentTitle sub-body-bold">Status</p>
          <template v-for="(item, i) in statusItems">
            <v-checkbox
              color="#9E8819"
              :key="i"
              v-model="statusSelected"
              :label="item.text"
              :value="item.value"
              class="mt-0 filterContent body-regular"
              hide-details
            ></v-checkbox>
          </template>
        </div>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import FilterButton from '@/components/FilterButton'
import {doctorStatus} from '@/constants/doctor'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    departmentSelected: null,
    specialtySelected: null,
    statusSelected: [],
    countFiltered: 0,
    appliedStatus: [],
    appliedDepartments: null,
    appliedSpecialty: null,
  }),
  components: {
    FilterButton,
  },
  computed: {
    statusItems() {
      const items = []
      Object.keys(doctorStatus).forEach(key => {
        items.push({
          text: doctorStatus[key],
          value: key
        })
      })
      return items
    },
    isClearFilter() {
      const changedDepartment = !_.isEqual(this.departmentSelected, null)
      const changedSpecialty = !_.isEqual(this.specialtySelected, null)
      const changedStatus = !_.isEqual(this.statusSelected, [])
      return (
        changedStatus || changedDepartment || changedSpecialty
      )
    },
    isChangeFilter() {
      const changedDepartment = !_.isEqual(
        this.departmentSelected,
        this.appliedDepartments
      )
      const changedSpecialty = !_.isEqual(
        this.specialtySelected,
        this.appliedSpecialty
      )
      const changedStatus = !_.isEqual(this.statusSelected, this.appliedStatus)
      return (
        changedStatus || changedDepartment || changedSpecialty
      )
    },
    ...mapState('doctor', ['departmentList', 'specialtyListTh'])
  },
  watch: {
    departmentList() {
      if (this.departmentList.length === 0) {
        this.clearDepartmentSelected()
      }
    }
  },
  methods: {
    clearDepartmentSelected() {
      this.departmentSelected = null
    },
    sumFiltered() {
      const sumStatus = this.statusSelected.length > 0 ? 1 : 0
      const sumDepartment = this.departmentSelected === null ? 0 : 1
      const sumSpecialty = this.specialtySelected === null ? 0 : 1
      return sumStatus + sumDepartment + sumSpecialty
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedStatus = this.statusSelected
      this.appliedDepartments = this.departmentSelected
      this.appliedSpecialty = this.specialtySelected
      this.setFilterStatus(this.statusSelected)
      this.setFilterDepartment(this.departmentSelected)
      this.setFilterSpecialty(this.specialtySelected)
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      this.statusSelected = []
      this.departmentSelected = null
      this.specialtySelected = null
    },
    handleCancelFilter() {
      // wait for multiple select render
      this.$nextTick(() => {
        this.statusSelected = this.appliedStatus
        this.departmentSelected = this.appliedDepartments
        this.specialtySelected = this.appliedSpecialty
        this.setFilterStatus(this.statusSelected)
        this.setFilterDepartment(this.departmentSelected)
        this.setFilterSpecialty(this.specialtySelected)
        this.countFiltered = this.sumFiltered()
      })
    },
    ...mapActions('doctor', [
      'fetch',
      'setFilterDepartment',
      'setFilterStatus',
      'setFilterSpecialty'
    ])
  }
}
</script>
